/* eslint-disable react-hooks/exhaustive-deps */
import logo from '../../logo.svg';
import Button from '../UI/Button/Button';
import UserIcon from './UserIcon.svg';
// import { HiMenuAlt2 } from 'react-icons/hi';
import { IoClose } from 'react-icons/io5';
import { FaUserGear } from 'react-icons/fa6';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoLogOut } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../redux/features/userSlice';
import { useEffect, useState } from 'react';
import { ImProfile } from 'react-icons/im';
import { BiSolidEdit } from 'react-icons/bi';
// import { useCookies } from 'react-cookie';
import '../../App.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Navbar = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState();
  const [activePage, setActivePage] = useState('profile');
  const [isLoading, setIsLoading] = useState(false);

  const [expandNavMenu, setExpandNavMenu] = useState(false);

  const [isHambergureExpanded, setIsHambergureExpanded] = useState(false);

  const hambergureExpandedToggle = () => {
    setIsHambergureExpanded(!isHambergureExpanded);
  };

  const toggleExpandNavMenu = () => {
    setExpandNavMenu(!expandNavMenu);
  };

  const [profilePicUrl, setProfilePicUrl] = useState(null);

  const profilePicUrlMyDetail = useSelector(
    (state) => state.image.profilePicUrlMyDetail
  );
  // console.log('profilePicUrlMyDetail: ', profilePicUrlMyDetail);

  const token = localStorage.getItem('auth_token');

  // const [cookies] = useCookies(['laravel_session']);
  // console.log('cookies: ', cookies);

  useEffect(() => {
    if (profilePicUrlMyDetail) {
      const fullPorfilePicUrl = BACKEND_URL + profilePicUrlMyDetail;
      setProfilePicUrl(fullPorfilePicUrl);
    }
  }, [profilePicUrlMyDetail]);

  useEffect(() => {
    // Redirect to dashboard only if on the home or login/signup page
    if (
      token &&
      (location.pathname === '/' ||
        location.pathname === '/signin' ||
        location.pathname === '/signup')
    ) {
      navigate('/dashboard');
    }
  }, [token, location, navigate]);

  const images = useSelector((state) => state.image.companyLogoImages);
  let img = images && images[0] && images[0].url;
  const fullImageUrl = img ? BACKEND_URL + img : '';

  useEffect(() => {
    if (fullImageUrl) {
      setCompanyLogo(fullImageUrl);
    }
  }, [fullImageUrl]);

  const loggedInUser = useSelector((state) => state.user.userInfo);

  // useEffect(() => {
  // if (loggedInUser === null) {
  // console.log('undefined');
  // localStorage.removeItem('auth_token');
  // window.location.reload();
  // }
  // }, []);

  // console.log('loggedInUser: ', loggedInUser, loggedInUser === null);

  let companyInfoArray = useSelector((state) => state.companyMaster.info);

  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const { company_name } = companyInfo || {};
  // console.log(company_name);

  let userName = loggedInUser?.additional_details?.first_name || '';
  userName = userName?.charAt(0).toUpperCase() + userName?.slice(1);

  const handleLogout = async () => {
    // console.log('logout');

    try {
      setIsLoading(true);
      await dispatch(logoutUser());

      // if (logoutRes) {
      // const status = logoutRes?.payload?.status;
      //##[25 Nov 2024] We need to replacce this code into userSlice file because auth_token does not removed proper
      // if (status === 200) {
      //   localStorage.removeItem('auth_token');
      //   props.onShowSuccessMessage('Successfully logged out.');
      //   navigate('/');
      // }
      // }
      setIsLoading(false);
      toggleExpandNavMenu();
    } catch (error) {
      console.error('Error logging out:', error.message);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <header
      className={`${props.className} relative flex justify-center items-center top-0 left-0 w-[100%] z-50 bg-[#FFFFFF] h-[4rem] md:h-[5rem] shadow-[0_0_8px_0_rgba(0,0,0,0.25)]`}
    >
      <nav className='w-[85%] mx-auto flex justify-between items-center px-2'>
        <Link
          to='/'
          className='flex justify-center items-center gap-1 text-[#FF4061] text-lg font-semibold'
        >
          <img src={companyLogo || logo} alt='logo' className='w-11 md:w-14' />

          <span className='text-[rgb(7,7,7)] text-[18px] md:text-[22px]'>
            {company_name}
          </span>
        </Link>
        <div className='lg:hidden'>
          <div
            onClick={hambergureExpandedToggle}
            className='w-7 h-7 relative cursor-pointer gap-4'
          >
            <span className='bg-[#FF4061] h-[4px] w-[1.5rem] rounded-full absolute shadow-lg top-0 width-animate-1'></span>
            <span className='bg-[#FF4061] h-[4px] w-[1.8rem] rounded-full absolute shadow-lg top-[30%] width-animate-2'></span>
            <span className='bg-[#FF4061] h-[4px] w-[1.2rem] rounded-full absolute shadow-lg top-[60%] width-animate-3'></span>
          </div>
        </div>
        <div
          className={`flex flex-col lg:flex-row justify-start lg:justify-center items-start lg:items-center bg-[rgb(244,244,244)] lg:bg-transparent absolute lg:static h-[calc(100vh-4rem)] md:h-[calc(100vh-5rem)] lg:h-auto w-[70%] md:w-[60%] lg:w-auto left-0 lg:right-0 top-[4rem] md:top-[5rem] transition-transform ease-in-out duration-300 ${
            isHambergureExpanded
              ? 'z-[9980] translate-x-[-100]'
              : 'translate-x-[-100%] lg:translate-x-0'
          }`}
        >
          <div className='w-full flex justify-end items-center lg:hidden pt-2 pr-2'>
            <IoClose
              onClick={() => {
                setIsHambergureExpanded(false);
              }}
              className='text-[24px] md:text-[34px] cursor-pointer'
            />
          </div>
          {!token && (
            <div className='flex flex-col lg:flex-row justify-center items-start lg:items-center lg:mr-10 px-5 md:px-10 lg:px-0'>
              <Link to='/' onClick={() => setIsHambergureExpanded(false)}>
                <button
                  className={`text-[rgb(7,7,7)] text-[18px] md:text-[24px] lg:text-[18px] rounded-[30px] px-2 py-1.5 mr-2 hover:text-[#FF3F60] transition-all duration-100 ${
                    location.pathname === '/'
                      ? 'font-bold underline underline-offset-4'
                      : ''
                  }`}
                >
                  Home
                </button>
              </Link>
              <Link
                to='/about-us'
                onClick={() => setIsHambergureExpanded(false)}
              >
                <button
                  className={`text-[rgb(7,7,7)] text-[18px] md:text-[24px] lg:text-[18px] rounded-[30px] px-2 py-1.5 mr-2 hover:text-[#FF3F60] transition-all duration-100 ${
                    location.pathname === '/about-us'
                      ? 'font-bold underline underline-offset-4'
                      : ''
                  }`}
                >
                  About US
                </button>
              </Link>
              <Link
                to='/contact-us'
                onClick={() => setIsHambergureExpanded(false)}
              >
                <button
                  className={`text-[rgb(7,7,7)] text-[18px] md:text-[24px] lg:text-[18px] rounded-[30px] px-2 py-1.5 mr-2 hover:text-[#FF3F60] transition-all duration-100 ${
                    location.pathname === '/contact-us'
                      ? 'font-bold underline underline-offset-4'
                      : ''
                  }`}
                >
                  Contact Us
                </button>
              </Link>
            </div>
          )}

          {!token && (
            <div className='flex flex-col lg:flex-row justify-center items-start lg:items-center gap-4 px-5 md:px-10 lg:px-0 mt-3 lg:mt-0'>
              <Link
                to='/signin'
                onClick={() => setIsHambergureExpanded(false)}
                class='relative inline-flex items-center justify-start px-5 py-1.5 overflow-hidden font-bold rounded-full group'
              >
                <span class='w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]'></span>
                <span class='absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[rgb(46,5,11)] opacity-100 group-hover:-translate-x-8'></span>
                <span class='relative w-full text-left text-gray-900 transition-colors duration-200 ease-in-out group-hover:text-white text-[16px] md:text-[24px] lg:text-[16px]'>
                  LOG IN
                </span>
                <span class='absolute inset-0 border-2 border-[rgb(46,5,11)] rounded-full'></span>
              </Link>

              <Link
                to='/signup'
                onClick={() => setIsHambergureExpanded(false)}
                class='relative inline-flex items-center justify-start px-5 py-1.5 overflow-hidden font-bold rounded-full group'
              >
                <span class='w-32 h-32 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]'></span>
                <span class='absolute top-0 left-0 w-48 h-48 -mt-1 transition-all duration-500 ease-in-out rotate-45 -translate-x-56 -translate-y-24 bg-[rgb(255,63,96)] opacity-100 group-hover:-translate-x-8'></span>
                <span class='relative w-full text-left text-gray-900 transition-colors duration-200 ease-in-out group-hover:text-white text-[16px] md:text-[24px] lg:text-[16px]'>
                  REGISTER
                </span>
                <span class='absolute inset-0 border-2 border-[rgb(255,63,96)] rounded-full'></span>
              </Link>
            </div>
          )}
          <div className='relative flex justify-center items-center ml-2 group px-'>
            {token && (
              <Button
                textColor='#333333'
                text={userName}
                onButtonClick={toggleExpandNavMenu}
                className='!bg-[#FFFFFF] lg:!font-bold !text-[#FF4061] border-2 border-[#FF4061] px-2 shadow-none lg:shadow-lg transition duration-300'
              >
                <img
                  className='rounded-full'
                  src={profilePicUrl || UserIcon}
                  width={30}
                  alt='user'
                />
                <span className='text-[#ff0000]'>Hello</span>
              </Button>
            )}
            {/* ////////////// */}
            {/* Mobile */}
            {token && (
              <div className='lg:hidden absolute right-auto top-[100%] w-[100%] text-white bg-[rgba(255,255,255,1)] rounded-b-lg transition lg:border-b-4 lg:border-[#FFFFFF] mt-4'>
                <ul className='flex flex-col justify-center items-start w-full bg-red-400'>
                  <Link
                    onClick={() => {
                      setActivePage('profile');
                      setIsHambergureExpanded(false);
                    }}
                    to='/dashboard'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'profile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <FaUserGear size={24} className='' />
                      <p className='ml-2'>Profile</p>
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      setActivePage('findprofile');
                      setIsHambergureExpanded(false);
                    }}
                    to='/findprofile'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'findprofile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <ImProfile size={25} />
                      <p className='ml-2'>Find Profile</p>
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      setActivePage('edit-profile');
                      setIsHambergureExpanded(false);
                    }}
                    to='/edit-profile'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'edit-profile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <BiSolidEdit size={26} />
                      <p className='ml-2'>Edit Profile</p>
                    </li>
                  </Link>
                  <li
                    onClick={handleLogout}
                    className='flex justify-start items-start hover:bg-[#FF4061aa] bg-[#f4f4f4] text-[#333333] hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200'
                  >
                    <IoLogOut size={26} />
                    <p className='ml-2'>Logout</p>
                    {isLoading && (
                      <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                        <svg
                          className='animate-spin h-6 w-6 text-pink-500'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8v8H4z'
                          ></path>
                        </svg>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            )}

            {/* ////////////// */}
            {/* Large Screen */}
            {expandNavMenu && (
              <div className='hidden lg:block absolute right-auto top-[100%] w-[95%] text-white bg-[rgba(255,255,255,1)] rounded-b-lg transition lg:border-b-4 lg:border-[#FFFFFF]'>
                <ul className='flex flex-col justify-center items-start w-full pt-8 lg:pb-2'>
                  <Link
                    onClick={() => {
                      setActivePage('profile');
                    }}
                    to='/dashboard'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'profile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <FaUserGear size={24} className='' />
                      <p className='ml-2'>Profile</p>
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      setActivePage('findprofile');
                    }}
                    to='/findprofile'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'findprofile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <ImProfile size={25} />
                      <p className='ml-2'>Find Profile</p>
                    </li>
                  </Link>
                  <Link
                    onClick={() => {
                      setActivePage('edit-profile');
                    }}
                    to='/edit-profile'
                    className='w-full flex'
                  >
                    <li
                      onClick={toggleExpandNavMenu}
                      className={`flex justify-start items-start hover:bg-[#FF4061aa] ${
                        activePage === 'edit-profile'
                          ? 'bg-[#FF4061] text-[#FFFFFF]'
                          : 'bg-[#f4f4f4] text-[#333333]'
                      } hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200`}
                    >
                      <BiSolidEdit size={26} />
                      <p className='ml-2'>Edit Profile</p>
                    </li>
                  </Link>
                  <li
                    onClick={handleLogout}
                    className='flex justify-start items-start hover:bg-[#FF4061aa] bg-[#f4f4f4] text-[#333333] hover:text-[#FFFFFF] cursor-pointer w-full px-2 py-3 duration-200'
                  >
                    <IoLogOut size={26} />
                    <p className='ml-2'>Logout</p>
                    {isLoading && (
                      <div className='flex justify-center items-center bg-white rounded-full ml-2'>
                        <svg
                          className='animate-spin h-6 w-6 text-pink-500'
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                        >
                          <circle
                            className='opacity-25'
                            cx='12'
                            cy='12'
                            r='10'
                            stroke='currentColor'
                            strokeWidth='4'
                          ></circle>
                          <path
                            className='opacity-75'
                            fill='currentColor'
                            d='M4 12a8 8 0 018-8v8H4z'
                          ></path>
                        </svg>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
