import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
// console.log(BACKEND_URL + '/api/util/country');

// Async thunks to fetch data from APIs
export const fetchCountry = createAsyncThunk(
  'fields/fetchCountry',
  async () => {
    try {
      const response = await axios.get(BACKEND_URL + '/api/util/country'); // API endpoint
      // console.log('fetchCountry: ', response.data.country);

      return response.data.country;
    } catch (error) {
      // console.log(error);
    }
  }
);

export const fetchStates = createAsyncThunk('fields/fetchStates', async () => {
  const response = await axios.get(BACKEND_URL + '/api/util/states'); // API endpoint
  // console.log('fetchStates: ', response.data.state);

  return response.data.state;
});

export const fetchCity = createAsyncThunk('fields/fetchCity', async () => {
  const response = await axios.get(BACKEND_URL + '/api/util/city'); // API endpoint
  // console.log('fetchCity: ', response.data.city);
  return response.data.city;
});

export const fetchReligion = createAsyncThunk(
  'fields/fetchReligion',
  async () => {
    const response = await axios.get(BACKEND_URL + '/api/util/religion'); // API endpoint
    // console.log("fetchReligion: ", response.data.religion);
    return response.data.religion;
  }
);

export const fetchCommunity = createAsyncThunk(
  'fields/fetchCommunity',
  async () => {
    const response = await axios.get(BACKEND_URL + '/api/util/community'); // API endpoint
    // console.log("fetchCommunity: ", response.data.community);
    return response.data.community;
  }
);

export const fetchSubCommunity = createAsyncThunk(
  'fields/fetchSubCommunity',
  async () => {
    const response = await axios.get(BACKEND_URL + '/api/util/community-sub'); // API endpoint
    // console.log('fetchSubCommunity: ', response.data.sub_community);
    return response.data.sub_community;
  }
);

export const fetchWeight = createAsyncThunk('fields/fetchWeight', async () => {
  const response = await axios.get(BACKEND_URL + '/api/util/weight'); // API endpoint
  // console.log("fetchWeight: ", response.data.weight);
  return response.data.weight;
});

export const fetchMotherTounge = createAsyncThunk(
  'fields/fetchMotherTounge',
  async () => {
    const response = await axios.get(BACKEND_URL + '/api/util/mother-tounge'); // API endpoint
    // console.log("fetchMotherTounge: ", response.data.mother_tounge:);
    return response.data.mother_tounge;
  }
);

export const fetchQualificationType = createAsyncThunk(
  'fetchQualificationType',
  async () => {
    const response = await axios.get(
      BACKEND_URL + '/api/util/qualification-type'
    ); // API endpoint
    // console.log('fetchQualificationType: ', response.data.qualification_type);
    return response.data.qualification_type;
  }
);

export const fetchHighestQualification = createAsyncThunk(
  'fetchHighestQualification',
  async () => {
    const response = await axios.get(
      BACKEND_URL + '/api/util/highest-qualification'
    ); // API endpoint
    // console.log(
    //   "fetchHighestQualification: ",
    //   response.data.highest_qualification
    // );
    return response.data.highest_qualification;
  }
);

export const fetchOccupation = createAsyncThunk('fetchOccupation', async () => {
  const response = await axios.get(BACKEND_URL + '/api/util/occupation'); // API endpoint
  // console.log("fetchOccupation: ", response.data.occupation);
  return response.data.occupation;
});

export const fetchAnnualIncome = createAsyncThunk(
  'fetchAnnualIncome',
  async () => {
    const response = await axios.get(BACKEND_URL + '/api/util/annual-income'); // API endpoint
    // console.log('fetchAnnualIncome: ', response.data.annual_income);
    return response.data.annual_income;
  }
);

export const fetchPhysicalStatus = createAsyncThunk(
  'fetchPhysicalStatus',
  async () => {
    const response = await axios.get(BACKEND_URL + '/api/util/physical-status'); // API endpoint
    // console.log('fetchPhysicalStatus: ', response.data);
    return response.data;
  }
);

export const fetchDietaryPreferences = createAsyncThunk(
  'fetchDietaryPreferences',
  async () => {
    const response = await axios.get(
      BACKEND_URL + '/api/util/dietary_preferences'
    ); // API endpoint
    // console.log('fetchDietaryPreferences: ', response.data.DietaryPreference);
    return response.data.DietaryPreference;
  }
);

const fieldsSlice = createSlice({
  name: 'formFields',
  initialState: {
    country: [],
    states: [],
    city: [],
    religion: [],
    community: [],
    subCommunity: [],
    weight: [],
    motherTounge: [],
    qualificationType: [],
    highestQualification: [],
    occupation: [],
    annualIncome: [],
    physicalStatus: [],
    dietaryPreferences: [],
    status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Country Fetch Cases
      .addCase(fetchCountry.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.country = action.payload;
        state.loading = false;
      })
      .addCase(fetchCountry.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // States Fetch Cases
      .addCase(fetchStates.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.states = action.payload;
        state.loading = false;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // States Fetch Cases
      .addCase(fetchCity.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchCity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.city = action.payload;
        state.loading = false;
      })
      .addCase(fetchCity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Religion Fetch Cases
      .addCase(fetchReligion.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchReligion.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.religion = action.payload;
        state.loading = false;
      })
      .addCase(fetchReligion.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Community Fetch Cases
      .addCase(fetchCommunity.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchCommunity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.community = action.payload;
        state.loading = false;
      })
      .addCase(fetchCommunity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Sub-Community Fetch Cases
      .addCase(fetchSubCommunity.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchSubCommunity.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.subCommunity = action.payload;
        state.loading = false;
      })
      .addCase(fetchSubCommunity.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Weight Fetch Cases
      .addCase(fetchWeight.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchWeight.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.weight = action.payload;
        state.loading = false;
      })
      .addCase(fetchWeight.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Mother Tounge Fetch Cases
      .addCase(fetchMotherTounge.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchMotherTounge.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.motherTounge = action.payload;
        state.loading = false;
      })
      .addCase(fetchMotherTounge.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Qualification Type Fetch Cases
      .addCase(fetchQualificationType.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchQualificationType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.qualificationType = action.payload;
        state.loading = false;
      })
      .addCase(fetchQualificationType.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Highest Qualification Fetch Cases
      .addCase(fetchHighestQualification.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchHighestQualification.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.highestQualification = action.payload;
        state.loading = false;
      })
      .addCase(fetchHighestQualification.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Occupation Fetch Cases
      .addCase(fetchOccupation.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchOccupation.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.occupation = action.payload;
        state.loading = false;
      })
      .addCase(fetchOccupation.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })

      // Annual Income Fetch Cases
      .addCase(fetchAnnualIncome.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchAnnualIncome.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.annualIncome = action.payload;
        state.loading = false;
      })
      .addCase(fetchAnnualIncome.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })
      // Physical Status Fetch Cases
      .addCase(fetchPhysicalStatus.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchPhysicalStatus.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.physicalStatus = action.payload;
        state.loading = false;
      })
      .addCase(fetchPhysicalStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      })
      // Dietary Preferences Fetch Cases
      .addCase(fetchDietaryPreferences.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
      })
      .addCase(fetchDietaryPreferences.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dietaryPreferences = action.payload;
        state.loading = false;
      })
      .addCase(fetchDietaryPreferences.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default fieldsSlice.reducer;
