// import Button from '../UI/Button/Button';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import '../../index.css';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { current } from '@reduxjs/toolkit';

const Footer = (props) => {
  const version = process.env.REACT_APP_VERSION || '1.1.0';
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const {
    company_name,
    // email,
    // contact_number_1,
    // contact_number_2,
    // whatsapp_number,
    facebook,
    linkdin,
    instagram,
    // x_twitter,
    youtube,
    copyright_start_year,
  } = companyInfo || {};
  // console.log('companyInfo: ', companyInfo);

  const currentYear = new Date().getFullYear();
  // console.log(currentYear);//2024

  const clearCasheHandler = () => {
    localStorage.removeItem('auth_token');
    window.location.reload(true);
  };

  return (
    <footer
      className={`flex justify-center items-center w-[100%] bg-[rgb(46,5,11)] pb-8 md:pb-10 pt-16`}
    >
      <div className='flex flex-col justify-center items-center w-[90%] lg:w-[80%]'>
        <div className='flex flex-col justify-center items-center w-full '>
          <ul className='flex flex-col md:flex-col gap-y-3 lg:flex-row justify-between items-start w-[100%]'>
            <li className='flex flex-col justify-center items-center lg:items-start w-full md:w-full lg:w-[65%]'>
              <div className='flex flex-col justify-start items-start gap-6'>
                <p className='text-white text-[24px] lg:text-[40px] font-bold text-center lg:text-left'>
                  Join Our Community Today!
                </p>
                <p className='text-white text-[18px] lg:text-[24px] text-center lg:text-left leading-relaxe tracking-wide lg:pr-20'>
                  Take the first step towards finding your life partner. It’s
                  free, secure, and tailored for you. Together, let’s make your
                  journey to love effortless and fulfilling.
                </p>
              </div>
              <p className='text-[24px] lg:text-[40px] font-medium lg:font-bold text-[#FFFFFF] mb-2 text-left w-full mt-14'>
                Follow Us
              </p>
              <div className='flex justify-start items-center gap-4 lg:gap-6 mt-4 w-full'>
                <a
                  href={facebook}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='rounded-full'
                >
                  <FaFacebook className='text-white text-[34px] lg:text-[42px]' />
                </a>
                <a
                  href={youtube}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='rounded-full bg-white p-2'
                >
                  <FaYoutube className='text-[rgb(46,5,11)] text-[20px] lg:text-[24px]' />
                </a>
                <a
                  href={instagram}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='rounded-full bg-white p-2'
                >
                  <FaInstagram className='text-[rgb(46,5,11)] text-[20px] lg:text-[24px]' />
                </a>
                <a
                  href={linkdin}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='rounded-full bg-white p-2'
                >
                  <FaLinkedinIn className='text-[rgb(46,5,11)] text-[20px] lg:text-[24px]' />
                </a>
              </div>
              <div className='mt-8 mb-4 lg:mb-0 hidden lg:block'>
                <p className='text-white text-[18px] text-left lg:text-[24px]'>
                  {company_name} © 2012 - 2025. All rights reserved.
                </p>
              </div>
            </li>
            <li className='flex flex-col justify-center items-center w-full md:w-full lg:w-[30%]'>
              <ul className='flex flex-col justify-center items-center gap-2 leading-loose tracking-wide w-full'>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[24px] lg:text-[26px] font-medium lg:font-bold text-[#FFFFFF] lg:mb-4 text-left lg:text-center lg:text- w-full lg:w-auto'>
                    Quick Links
                  </p>
                </li>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[#EEEEEE] font-normal lg:font-medium text-[18px] lg:text-[24px] mt-1 text-center lg:text-start '>
                    <Link to='/cancellation-policy'>Cancellation Policy</Link>
                  </p>
                </li>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[#EEEEEE] font-normal lg:font-medium text-[18px] lg:text-[24px] mt-1 text-center lg:text-start'>
                    <Link to='/about-us'>About Us</Link>
                  </p>
                </li>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[#EEEEEE] font-normal lg:font-medium text-[18px] lg:text-[24px] mt-1 text-center lg:text-start'>
                    <Link to='/terms-condition'>Terms & Conditions</Link>
                  </p>
                </li>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[#EEEEEE] font-normal lg:font-medium text-[18px] lg:text-[24px] mt-1 text-center lg:text-start'>
                    <Link to='/refund-policy'>Refund Policy</Link>
                  </p>
                </li>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[#EEEEEE] font-normal lg:font-medium text-[18px] lg:text-[24px] mt-1 text-center lg:text-start'>
                    <Link to='/privacy-policy'>Privacy policy</Link>
                  </p>
                </li>
                <li className='flex justify-start items-center w-full hover:underline transition'>
                  <p className='text-[#EEEEEE] font-normal lg:font-medium text-[18px] lg:text-[24px] mt-1 text-center lg:text-start'>
                    <Link to='/contact-us'>Contact Us</Link>
                  </p>
                </li>
              </ul>
              <div className='mt-8 mb-4 lg:mb-0 block lg:hidden'>
                <p className='text-white text-[14px] text-left lg:text-[24px]'>
                  KatiyaSamaj.com © {copyright_start_year} - {currentYear}. All
                  rights reserved.
                </p>
              </div>
            </li>
          </ul>
          <span className='w-[50%] h-[.8px] lg:w-full lg:h-[.8px] bg-[#CCCCCCaa] self-center my-3'></span>
          <div className='flex flex-col justify-center items-center md:pt-8'>
            <p className='text-center text-[#FFFFFF] text-[14px] font-normal'>
              Deployement Version: {version}
            </p>
            <div className='flex flex-col md:flex-row gap-4'>
              <button
                onClick={clearCasheHandler}
                className='px-2 py-1 border text-white rounded-lg mt-2 shadow-md w-auto'
              >
                Clear Cache
              </button>
              <Link
                to='/mobile-trubleshoot-guide'
                className='px-2 py-1 border text-white rounded-lg mt-2 shadow-md'
              >
                Mobile User Troubleshoot Guide
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
