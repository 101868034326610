import { Helmet } from 'react-helmet-async';

const PrivacyPolicy = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <Helmet>
        <title>Privacy Policy | katiyasamaj.com</title>
        <meta
          name='description'
          content='Read the Privacy Policy of KatiyaSamaj.com to understand how we collect, use, and protect your personal information. Learn about data security, user privacy, and confidentiality.'
        />
      </Helmet>
      <section className='bg-gray-50 w-full mb-20'>
        <div className='py-12 bg-[#FF8C9C]'>
          <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
            Privacy Policy
          </h1>
        </div>
        {/* Content Section */}
        <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
          {/* <div className='max-w-4xl mx-auto py-10 px-5 sm:px-10 lg:px-20'> */}
          <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
            {/* Introduction Section */}
            <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
              Privacy Policy:
            </h2>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              Your privacy is important to us. This Privacy Policy explains how
              we collect, use, and protect your personal information.
            </p>

            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Information We Collect:
            </h3>
            <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4 mt-4'>
              <li>
                <p>
                  <strong>Personal Information:</strong> When you register or
                  update your profile, we collect personal details such as your
                  name, contact details, and other relevant information.
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage Data:</strong> We collect data on how you
                  interact with our website (e.g., pages visited, time spent) to
                  enhance user experience.
                </p>
              </li>
            </ul>
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              How We Use Your Information:
            </h3>
            <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4 mt-4'>
              <li>
                <p>To provide personalized matchmaking services.</p>
              </li>
              <li>
                <p>
                  To communicate important updates or offers related to our
                  services.
                </p>
              </li>
              <li>
                <p>To ensure the security and integrity of our platform.</p>
              </li>
            </ul>
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Data Protection:
            </h3>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              We implement industry-standard security measures to protect your
              data. However, we cannot guarantee absolute security, and you are
              advised to take necessary precautions when using our platform.
            </p>
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Third-Party Disclosure:
            </h3>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              We do not share your personal information with third parties,
              except as required by law or to improve our services through
              trusted partners.
            </p>
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Changes to Privacy Policy:
            </h3>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              We may update this policy from time to time. Any changes will be
              posted on this page.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
