import { Helmet } from 'react-helmet-async';

const TermsAndCondition = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <Helmet>
        <title>Terms & Condition | katiyasamaj.com</title>
        <meta
          name='description'
          content='Read the Terms & Conditions of KatiyaSamaj.com. Learn about our user guidelines, privacy policies, membership rules, and legal terms for using our matrimonial services.'
        />
      </Helmet>
      <section className='bg-gray-50 w-full mb-20'>
        <div className='py-12 bg-[#FF8C9C]'>
          <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
            Terms & Condition
          </h1>
        </div>
        {/* Content Section */}
        <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
          <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
            {/* Introduction Section */}
            <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
              Terms & Conditions:
            </h2>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              By using
              <span className='font-semibold text-[#FF8C9C] ml-1'>
                KatiyaSamaj.com
              </span>
              , you agree to the following terms and conditions. Please read
              these terms carefully before accessing or using our services.
            </p>

            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              General Terms:
            </h3>
            <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4 mt-4'>
              <li>
                <p>
                  <strong>Eligibility:</strong> You must be a member of the
                  Katiya community to use the services of
                  <span className='font-semibold text-[#FF8C9C] ml-1'>
                    KatiyaSamaj.com
                  </span>
                </p>
              </li>
              <li>
                <p>
                  <strong>Account Registration:</strong> When registering for an
                  account, you are required to provide accurate and truthful
                  information.
                </p>
              </li>
              <li>
                <p>
                  <strong>Usage:</strong> You agree not to use our platform for
                  illegal purposes or to engage in any activity that may disrupt
                  or harm the platform’s functionality.
                </p>
              </li>
            </ul>
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              User Responsibilities:
            </h3>
            <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4 mt-4'>
              <li>
                <p>
                  <strong>Profile Information:</strong> You are solely
                  responsible for the accuracy of the information you provide in
                  your profile.
                </p>
              </li>
              <li>
                <p>
                  <strong>Account Security:</strong> You must protect your
                  account credentials and notify us immediately of any
                  unauthorized access.
                </p>
              </li>
            </ul>
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Modification of Terms:
            </h3>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              Katiya Welfare Society reserves the right to modify or update
              these terms. Any changes will be posted on the website, and your
              continued use of the services will signify your acceptance of
              these changes.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndCondition;
