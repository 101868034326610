import { useSelector } from 'react-redux';
import QuickResponseAnimation from './quick-response-animation.mp4';
import { FaWhatsapp } from 'react-icons/fa6';

const ContactUs = () => {
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const {
    // company_name,
    email,
    contact_number_1,
    // contact_number_2,
    whatsapp_number,
  } = companyInfo || {};

  return (
    <section className='flex justify-center items-center w-full py-4 lg:py-0 bg-[rgb(244,244,244)]'>
      <div className='flex flex-col-reverse md:flex-row justify-between items-center lg:gap-0 w-[90%] lg:w-full'>
        <div className='flex flex-col justify-start items-start gap-6 w-full md:w-[60%] lg:pl-[10%] '>
          <h2 className='text-[rgb(7,7,7)] text-[34px] lg:text-[48px] font-bold'>
            Quick Support
          </h2>
          <ul className='flex flex-col gap-2 text-[18px] lg:text-[22px] text-[rgb(8,8,8)]'>
            <li>
              <p>For support or inquiries, feel free to reach out:</p>
            </li>
            <li>
              <p>
                Email:<span className='text-[#BE2D46] ml-1'>{email}</span>
              </p>
            </li>
            <li>
              <p>
                Phone:
                <span className='text-[#BE2D46] ml-1'>{contact_number_1}</span>
              </p>
            </li>
            <li>
              <p className=''>
                Timing:
                <span className='text-[#BE2D46] ml-1'>
                  Monday to Saturday: 12 PM to 7 PM
                </span>
                {/* <strong>Monday</strong> to <strong>Saturday</strong>: 12 PM to
                    7 PM */}
              </p>
            </li>
            <li>
              <p className='flex justify-start items-center mb-2 text-center'>
                Whatsapp Number:
                {/* https://api.whatsapp.com/send/?phone=%2B919907546266&text=I%27m+Anup+Rakse+KWS15731+%22&type=phone_number&app_absent=0 */}
                <a
                  href={`https://wa.me/${whatsapp_number}?text=Request from Katiyasamaj.com`}
                  // href={`https://wa.me/${whatsapp_number}?text=I'm%20here%20from%20your%20website%20scrmp.in%20`}
                  className='text-[#FFFFFF] text-[18px] flex justify-center items-center bg-[#4CAF50] rounded-lg px-2 py-1 shadow-md hover:underline ml-1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FaWhatsapp size={20} />
                  {whatsapp_number}
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className='flex justify-end items-start w-full md:w-[40%] relative'>
          <video
            autoPlay
            muted
            loop
            playsInline
            className='w-full h-auto lg:h-[500px] object-cover mix-blend-darken'
          >
            <source src={QuickResponseAnimation} type='video/mp4' />
          </video>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
