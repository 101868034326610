import { Helmet } from 'react-helmet-async';
const AboutUs = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
  return (
    <>
      <Helmet>
        <title>About us | Katiya Samaj</title>
        <meta
          name='description'
          content='Learn about Katiya Samaj, its rich history, traditions, and community values. Explore how Katiya Samaj connects people through culture, heritage, and matrimonial services.'
        />
      </Helmet>

      <section className='bg-gray-50 w-full mb-20'>
        <div className='py-12 bg-[#FF8C9C]'>
          <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
            About Us
          </h1>
        </div>
        <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
          <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
            <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
              Katiya Welfare Society:
            </h2>
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              At Katiya Welfare Society, we strive to preserve and promote the
              cultural values of the Katiya community while fostering strong and
              lasting matrimonial relationships. Our website,{' '}
              <span className='font-semibold text-[#FF8C9C]'>
                KatiyaSamaj.com
              </span>
              , is dedicated to offering a secure and trustworthy platform for
              individuals within the Katiya community to connect and find
              compatible life partners.
            </p>

            <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4 mt-4'>
              <li>
                <p>
                  <strong>Mission:</strong> Our mission is to make the process
                  of finding a life partner easier, secure, and more efficient
                  for members of the Katiya community. We provide a
                  user-friendly interface and a variety of tools to ensure that
                  your matrimonial journey is smooth and fulfilling.
                </p>
              </li>
              <li>
                <p>
                  <strong>Vision:</strong> To be the leading platform for
                  matrimonial services for the Katiya community, upholding our
                  rich traditions while embracing modern solutions to connect
                  individuals in meaningful ways.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
