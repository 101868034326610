import { Helmet } from 'react-helmet-async';
import { MdEmail, MdPhone } from 'react-icons/md';
import { useSelector } from 'react-redux';

const ContactUs = () => {
  let companyInfoArray = useSelector((state) => state.companyMaster.info);
  const companyInfo = companyInfoArray?.length > 0 ? companyInfoArray[0] : null;
  const { email, contact_number_1, contact_number_2 } = companyInfo || {};

  window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <>
      <Helmet>
        <title>Contact Us | Katiya Samaj</title>
        <meta
          name='description'
          content='Get in touch with Katiya Samaj for inquiries, support, or community-related information. Contact us for matrimonial services, cultural events, and more.'
        />
      </Helmet>

      <section className='bg-gray-50 w-full mb-20'>
        <div className='py-12 bg-[#FF8C9C]'>
          <h1 className='text-white font-bold text-2xl sm:text-3xl lg:text-4xl px-20'>
            Contact Us
          </h1>
        </div>
        {/* Content Section */}
        <div className='max-w-6xl mx-auto py-10 px-5 sm:px-10 lg:px-20'>
          <div className='bg-white p-6 sm:p-10 rounded-lg shadow-md'>
            {/* Introduction Section */}
            {/* <h2 className='text-xl sm:text-2xl font-bold text-gray-800 mb-4'>
            Introduction:
          </h2> */}
            <p className='text-gray-700 text-base sm:text-lg leading-6 mb-6'>
              We love hearing from our members! If you have any questions,
              suggestions, or concerns, feel free to reach out to us. We are
              here to help.
            </p>
            {/* Contact Information Section */}
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Contact Information:
            </h3>
            <ul className='pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4'>
              <li className='flex justify-start items-center'>
                <MdEmail className='text-gray-700 mr-1' size={25} />
                <strong>Email:</strong>
                <a
                  href={`mailto:${email}`}
                  className='text-[#FF4061] hover:underline mx-1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {email}
                </a>
              </li>
              <li className='flex justify-start items-center'>
                <MdPhone className='text-gray-700 mr-1' size={25} />
                <strong>Phone:</strong>
                <a
                  href={`tel:${contact_number_1}`}
                  className='text-[#FF4061] hover:underline mx-2'
                >
                  {contact_number_1}
                </a>
              </li>
              <li className='flex justify-start items-center'>
                <MdPhone className='text-gray-700 mr-1' size={25} />
                <strong>Phone:</strong>
                <a
                  href={`tel:${contact_number_2}`}
                  className='text-[#FF4061] hover:underline mx-2'
                >
                  {contact_number_2}
                </a>
              </li>
            </ul>
            {/* Support Hours Section */}
            <h3 className='text-lg sm:text-xl font-semibold text-gray-800 mt-8 mb-4'>
              Support Hours:
            </h3>
            <ul className='list-disc pl-5 text-gray-700 text-base sm:text-lg leading-7 space-y-4'>
              <li>
                <strong>Monday to Saturday:</strong> 12 PM to 7 PM
              </li>
              <li>
                <strong>Sunday:</strong> Closed
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
