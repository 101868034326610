import QuickSupport from './sections/quick-support/QuickSupport';
import HeroSection from './sections/hero-section/HeroSection';
import HowItsWork from './sections/how-its-work/HowItsWork';
import OurMission from './sections/our-mission/OurMission';
import RegisterNow from './sections/register-now/RegisterNow';
import { Helmet } from 'react-helmet-async';

const Home = (props) => {
  return (
    <>
      <Helmet>
        <title>
          Katiya Samaj Matrimony | Katiya Shaadi | Katiya Brides & Grooms |
          KatiyaVivah | KatiyaSamaj.com
        </title>

        <meta
          name='description'
          content='Katiya Samaj Matrimony - The leading matrimonial website for the Katiya community. Find Katiya brides and grooms, explore Katiya Samaj history, and connect with like-minded individuals for marriage and matchmaking.'
        />
        <meta
          name='keywords'
          content='Katiya Matrimony, Katiya Matrimonial Services, Katiya Shaadi, Katiya Vivah, Katiya Samaj, Katiya Bride, Katiya Groom, Katiya Marriage, Katiya Wedding, Katiya Bandhan, Katiya Matchmaking'
        />
        <meta
          name='author'
          content='KatiyaSamaj.com - The trusted platform for Katiya Matrimony and Community Information'
        />
        <meta
          name='copyright'
          content='© 2012 KatiyaSamaj.com. All Rights Reserved.'
        />

        <meta name='robots' content='index, follow' />
      </Helmet>

      <HeroSection />
      <OurMission />
      <HowItsWork />
      <RegisterNow />
      <QuickSupport />
    </>
  );
};

export default Home;
