const OurMission = () => {
  return (
    <section className='flex justify-center items-center w-full bg-[rgb(46,5,11)]'>
      <div className='flex justify-center items-center gap-10 pt-20 lg:pt-52 pb-14 lg:pb-28 w-[90%] lg:w-[80%]'>
        <div className='flex flex-col justify-start items-start gap-6'>
          <h2 className='text-[rgb(244,244,244)] text-[44px] font-bold'>
            Our Mission
          </h2>
          <p className='text-[rgb(244,244,244)] text-[20px] md:text-[24px]'>
            We are dedicated to fostering meaningful relationships by uniting
            individuals from the Katiya community in their search for love and
            companionship. Our mission is to make the matrimonial journey
            seamless and accessible to everyone.
          </p>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
