import { LuBadge } from 'react-icons/lu';
import { BiCheckShield } from 'react-icons/bi';
import { ImProfile } from 'react-icons/im';
import Banner from './images/new-banner.jpg';
import { Link } from 'react-router-dom';

import '../../../../App.css';

const HeroSection = () => {
  return (
    <section
      className={`relative flex flex-col justify-center items-center w-[100%] lg:h-[calc(100vh-5rem)]`}
    >
      <div className='absolute z-20 w-full h-full bg-[linear-gradient(90deg,#cd324bcc_0%,transparent)] md:bg-[linear-gradient(90deg,#cd324b_10%,transparent)] lg:bg-[linear-gradient(90deg,#cd324b_35%,transparent)]'></div>
      <div className='absolute z-10 flex justify-end items-start w-full h-full'>
        <img
          src={Banner}
          alt='banner'
          className='h-full w-full md:w-auto object-cover object-top absolute'
        />
      </div>
      <div className='absolute z-20 flex justify-end items-start w-full h-full bg-[rgba(0,0,0,.5)]'></div>

      <div className='relative z-30 flex flex-col justify-center items-start w-[90%] lg:w-[90%] pt-[1rem] lg:pt-[5rem]'>
        <div className='flex flex-col justify-start items-center lg:items-start gap-4 md:gap-8 w-full translate-y-[10%]'>
          <h2
            className='text-[rgb(244,244,244)] font-bold lg:font-bold text-center lg:text-left tracking-wide lg:leading-snug'
            style={{ textShadow: '2px 2px 4px rgba(0,0,0,0.1)' }}
          >
            <span className='text-[24px] md:text-[24px] lg:text-[32px]'>
              Welcome to
            </span>
            <br />
            <span className='text-[48px] md:text-[72px] lg:text-[84px] text-[rgb(255,63,96)]'>
              Katiya Samaj
            </span>
            <br />
            <span className='flex justify-center items-center gap-2 text-[20px] md:text-[48px] lg:text-[30px] leading-none mt-2 md:mt-0 animate-charcter lg:leading-normal'>
              <span className='word-animate-1'>Shaadi</span>
              <span className='text-[rgba(244,244,244,0.75)] text-[20px] lg:text-[30px]'>
                |
              </span>
              <span className='word-animate-2'>Katiya Community</span>
              <span className='text-[rgba(244,244,244,0.75)] text-[20px] lg:text-[30px]'>
                |
              </span>
              <span className='word-animate-3'>Samachar</span>
            </span>
          </h2>
          <span className='w-40 md:w-80 lg:w-60 h-[3px] md:h-1 bg-[rgb(244,244,244)] rounded-full'></span>
          <p className='text-[rgb(244,244,244)] font-shadow md:mt-0 text-[18px] md:text-[22px] lg:text-[24px] text-center lg:text-start w-full lg:w-[45%]'>
            100% Free matrimonial service to find your life partner on
            KatiyaSamaj.com and stay informed with the latest Katiya community
            news on KatiyaSamachar.
          </p>
          <div className='flex justify-center items-center gap-4'>
            <Link
              to='/signup'
              class='relative inline-flex items-center justify-start px-4 md:px-8 py-2 overflow-hidden font-bold rounded-full group'
            >
              <span class='w-48 h-48 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]'></span>
              <span class='absolute top-0 left-0 w-60 h-60 px-4 md:px-8 transition-all duration-500 ease-in-out rotate-45 -translate-x-0 -translate-y-24 bg-[rgb(244,244,244)] opacity-100 group-hover:-translate-x-64'></span>
              <span class='relative w-full text-left text-gray-900 group-hover:text-[rgb(244,244,244)] transition-colors duration-200 ease-in-out text-[16px] md:text-[24px]'>
                REGISTER NOW
              </span>
              <span class='absolute inset-0 border-2 border-[rgb(244,244,244)] rounded-full'></span>
            </Link>

            <Link
              to='https://katiyasamachar.katiyasamaj.com/'
              target='_blank'
              class='relative inline-flex items-center justify-start px-4 md:px-8 py-2 overflow-hidden font-bold rounded-full group'
            >
              <span class='w-48 h-48 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]'></span>
              <span class='absolute top-0 left-0 w-60 h-60 px-4 md:px-8 transition-all duration-500 ease-in-out rotate-45 -translate-x-0 -translate-y-24 bg-[rgb(255,63,96)] opacity-100 group-hover:-translate-x-64'></span>
              <span class='relative w-full text-left text-white transition-colors duration-200 ease-in-out text-[16px] md:text-[24px]'>
                Katiya Samachar
              </span>
              <span class='absolute inset-0 border-2 border-[hsl(350,100%,62%)] rounded-full'></span>
            </Link>
          </div>
        </div>
      </div>
      <div className='relative z-30 mt-4 translate-y-[45%] md:translate-y-[60%] lg:translate-y-[40%] flex justify-center items-center w-full md:w-[90%] rounded-[20px] border-[3px] border-[rgb(244,244,244)] backdrop-blur-sm shadow-inner bg-[rgba(255,63,96,0.1)]'>
        <ul className='flex flex-col md:flex-row justify-center md:justify-around items-center gap-4 md:gap-0 py-3 md:py-6 md:w-[90%]'>
          <li className='flex justify-start md:justify-center items-center gap-4 text-[rgb(244,244,244)] w-full lg:w-auto'>
            <LuBadge
              className='text-[24px] md:text-[60px] lg:text-[136px]'
              strokeWidth={4}
            />

            <p className='font-bold md:text-[24px] lg:text-[30px] leading-none'>
              Free of Cost Registration
            </p>
          </li>
          <li className='flex justify-start md:justify-center items-center gap-4 text-[rgb(244,244,244)] w-full lg:w-auto'>
            <ImProfile className='text-[24px] md:text-[60px] lg:text-[136px]' />
            <p className='font-bold md:text-[24px] lg:text-[30px] leading-none'>
              100% Verified Profiles
            </p>
          </li>
          <li className='flex justify-start md:justify-center items-center gap-4 text-[rgb(244,244,244)] w-full lg:w-auto'>
            <BiCheckShield className='text-[24px] md:text-[60px] lg:text-[136px]' />
            <p className='font-bold md:text-[24px] lg:text-[30px] leading-none'>
              Privacy Protected
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HeroSection;
