import { Link } from 'react-router-dom';

const RegisterNow = () => {
  return (
    <section className='flex justify-center items-center w-full bg-[rgb(190,45,70)]'>
      <div className='flex flex-col md:flex-row lg:flex-row justify-center items-center gap-10 py-20 md:py-28 w-[90%] lg:w-[80%]'>
        <div className='flex justify-start items-start'>
          <h2 className='text-[rgb(244,244,244)] text-[34px] md:text-[28px] lg:text-[44px] font-bold text-center md:text-left'>
            Your story is waiting to happen!
          </h2>
        </div>
        <div className='flex justify-start items-start relative'>
          {/* <Link to='/signup'>
            <button className='bg-[rgb(244,244,244)] text-[rgb(8,8,8)] px-10 py-2 text-[20px] md:text-[24px] lg:text-[28px] font-semibold rounded-[50px] w-full hover:text-[rgb(244,244,244)] hover:shadow-2xl hover:bg-transparent hover:backdrop-blur-lg border-2 border-[rgb(244,244,244)] transition duration-300 animate-bounce'>
              REGISTER NOW
            </button>
          </Link> */}
          <Link
            to='/signup'
            class='relative inline-flex items-center justify-start px-4 md:px-8 py-2 overflow-hidden font-bold rounded-full animate-bounce group'
          >
            <span class='w-48 h-48 rotate-45 translate-x-12 -translate-y-2 absolute left-0 top-0 bg-white opacity-[3%]'></span>
            <span class='absolute top-0 left-0 w-60 h-60 px-4 md:px-8 transition-all duration-500 ease-in-out rotate-45 -translate-x-0 -translate-y-24 bg-[rgb(244,244,244)] opacity-100 group-hover:-translate-x-64'></span>
            <span class='relative w-full text-left text-gray-900 group-hover:text-[rgb(244,244,244)] transition-colors duration-200 ease-in-out text-[16px] md:text-[24px]'>
              REGISTER NOW
            </span>
            <span class='absolute inset-0 border-2 border-[rgb(244,244,244)] rounded-full'></span>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RegisterNow;
