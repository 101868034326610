// import { FaCheckCircle } from "react-icons/fa";
import { VscEye, VscEyeClosed } from 'react-icons/vsc';
import { AiOutlineLoading } from 'react-icons/ai';
import Button from '../../components/UI/Button/Button';
import Input from '../../components/UI/Input/Input';
import { useState, useEffect } from 'react';

const SignUpStep1 = ({ onNext, allPagesFormData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // console.log('allPagesFormData: ', allPagesFormData);

  const [formData, setFormData] = useState({
    mobile: allPagesFormData?.mobile_alternate || '',
    mobile_alternate: allPagesFormData?.mobile || '',
    first_name: allPagesFormData?.first_name || '',
    last_name: allPagesFormData.last_name || '',
    email: allPagesFormData?.email || '',
    confirm_email: allPagesFormData?.confirm_email || '',
    password: allPagesFormData?.password || '',
    password_confirmation: allPagesFormData?.password_confirmation || '',
  });

  const [formErrors, setFormErrors] = useState({
    mobile: '',
    mobile_alternate: '',
    first_name: '',
    last_name: '',
    email: '',
    confirm_email: '',
    password: '',
    password_confirmation: '',
  });

  const [touched, setTouched] = useState({
    mobile: false,
    mobile_alternate: false,
    first_name: false,
    last_name: false,
    email: false,
    confirm_email: '',
    password: false,
    password_confirmation: false,
  });

  const [isPasswordShow1, setIsPasswordShow1] = useState(false);
  const [isPasswordShow2, setIsPasswordShow2] = useState(false);

  useEffect(() => {
    if (touched.mobile) {
      if (/[^0-9]/.test(formData.mobile)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: 'Only digits are allowed!',
        }));
      } else if (formData.mobile && !/^\d{10}$/.test(formData.mobile)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: 'Please enter a valid Phone number.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile: '',
        }));
      }
    }
  }, [formData.mobile, touched.mobile]);

  useEffect(() => {
    if (touched.mobile_alternate) {
      if (/[^0-9]/.test(formData.mobile_alternate)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile_alternate: 'Only digits are allowed!',
        }));
      } else if (
        formData.mobile_alternate &&
        !/^\d{10}$/.test(formData.mobile_alternate)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile_alternate: 'Please enter a valid Phone number.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          mobile_alternate: '',
        }));
      }
    }
  }, [formData.mobile_alternate, touched.mobile_alternate]);

  useEffect(() => {
    if (touched.email) {
      if (
        formData.email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: 'Please enter a valid email address.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: '',
        }));
      }
    }
  }, [formData.email, touched.email]);

  useEffect(() => {
    if (touched.confirm_email) {
      if (
        formData.confirm_email &&
        !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.confirm_email)
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          confirm_email: 'Please enter a valid confirm email address.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          confirm_email: '',
        }));
      }
    }
  }, [formData.confirm_email, touched.confirm_email]);

  useEffect(() => {
    if (touched.password) {
      if (formData.password && formData.password.length < 8) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: 'Password must be at least 8 characters long.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password: '',
        }));
      }
    }
  }, [formData.password, touched.password]);

  useEffect(() => {
    if (touched.password_confirmation) {
      if (
        formData.password_confirmation &&
        formData.password_confirmation.length < 8
      ) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password_confirmation:
            'Password confirmation must be at least 8 characters long.',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          password_confirmation: '',
        }));
      }
    }
  }, [formData.password_confirmation, touched.password_confirmation]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setTouched((prevTouched) => ({
      ...prevTouched,
      [id]: true,
    }));
  };

  useEffect(() => {
    if (touched.first_name) {
      if (formData.first_name && !/^[a-zA-Z]+$/.test(formData.first_name)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          first_name: 'Enter first name without numbers!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          first_name: '',
        }));
      }
    }
  }, [formData.first_name, touched.first_name]);

  useEffect(() => {
    if (touched.last_name) {
      if (formData.last_name && !/^[a-zA-Z]+$/.test(formData.last_name)) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          last_name: 'Enter last name without numbers!',
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          last_name: '',
        }));
      }
    }
  }, [formData.last_name, touched.last_name]);

  const validateInputs = (data) => {
    let valid = true;
    const errors = {
      mobile: '',
      mobile_alternate: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      password_confirmation: '',
    };
    if (data.mobile === '') {
      valid = false;
      errors.mobile = 'Please enter phone number!';
    }
    // if (data.mobile_alternate === '') {
    //   valid = false;
    //   errors.mobile_alternate = 'Please enter phone number!';
    // }
    if (data.otp === '') {
      valid = false;
      errors.otp = 'Please verify OTP!';
    }
    if (data.email === '') {
      valid = false;
      errors.email = 'Please enter your email!';
    }
    if (data.confirm_email === '') {
      valid = false;
      errors.confirm_email = 'Please enter your confirm email!';
    }
    if (data.password === '') {
      valid = false;
      errors.password = 'Please enter password';
    }
    if (data.password_confirmation === '') {
      valid = false;
      errors.password_confirmation = 'Please enter password confirmation';
    }
    if (data.first_name === '') {
      valid = false;
      errors.first_name = 'Please enter first name!';
    }
    if (data.last_name === '') {
      valid = false;
      errors.last_name = 'Please enter last name!';
    }

    if (
      data.password &&
      data.password_confirmation &&
      data.password !== data.password_confirmation
    ) {
      valid = false;
      errors.password_confirmation = 'Password mismatch!';
    }

    if (data.email && data.confirm_email && data.email !== data.confirm_email) {
      valid = false;
      errors.confirm_email = 'Email mismatch!';
    }

    setFormErrors(errors);
    return valid;
  };

  const handleOnNext = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setError(null);

    if (!validateInputs(formData)) {
      setIsLoading(false);
      return;
    }

    try {
      formData.name = formData.first_name + ' ' + formData.last_name;
      formData.password_confirmation = formData.password;

      onNext(formData);
      // console.log('formData S1: ', formData);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleOnNext}
      className='flex flex-col justify-start items-start w-full md:w-[80%] lg:w-[50%] mt-6 md:h-auto'
    >
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <Input
            id='mobile'
            placeholder='Enter mobile no'
            label='Mobile Number:'
            value={formData.mobile}
            onChange={handleChange}
            className={`w-full ${formErrors.mobile ? 'border-red-500' : ''}`}
            error={formErrors.mobile}
          />
        </div>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <Input
            id='mobile_alternate'
            placeholder='Enter alternate no'
            label='Alternate Mobile No:'
            value={formData.mobile_alternate}
            onChange={handleChange}
            className={`w-full ${
              formErrors.mobile_alternate ? 'border-red-500' : ''
            }`}
            error={formErrors.mobile_alternate}
          />
        </div>
      </div>

      <div className='flex justify-between items-center w-full'>
        <div className='flex justify-center items-center w-[49%] md:w-[48%]'>
          <Input
            id='email'
            placeholder='Enter email'
            label='Email'
            value={formData.email}
            onChange={handleChange}
            className={`w-full ${formErrors.email ? 'border-red-500' : ''}`}
            error={formErrors.email}
          />
        </div>
        <div className='flex justify-center items-center w-[49%] md:w-[48%]'>
          <Input
            id='confirm_email'
            placeholder='Enter confirm email'
            label='Confirm Email:'
            value={formData.confirm_email}
            onChange={handleChange}
            className={`w-full ${
              formErrors.confirm_email ? 'border-red-500' : ''
            }`}
            error={formErrors.confirm_email}
          />
        </div>
      </div>

      <div className='flex justify-between items-center w-full'>
        <div className='flex justify-center items-center w-[49%] md:w-[48%]'>
          <Input
            id='password'
            type={isPasswordShow1 ? 'text' : 'password'}
            placeholder='Enter password'
            label='Password:'
            value={formData.password}
            onChange={handleChange}
            className={`w-full ${formErrors.password ? 'border-red-500' : ''}`}
            inputBtn={
              isPasswordShow1 ? (
                <VscEye className='!text-[#858585] mr-2' />
              ) : (
                <VscEyeClosed className='!text-[#858585] mr-2' />
              )
            }
            inputBtnHandler={() => setIsPasswordShow1(!isPasswordShow1)}
            error={formErrors.password}
          />
        </div>
        <div className='flex justify-center items-center w-[49%] md:w-[48%]'>
          <Input
            id='password_confirmation'
            type={isPasswordShow2 ? 'text' : 'password'}
            placeholder='Enter password'
            label='Confirm Password:'
            value={formData.password_confirmation}
            onChange={handleChange}
            className={`w-full ${
              formErrors.password_confirmation ? 'border-red-500' : ''
            }`}
            inputBtn={
              isPasswordShow2 ? (
                <VscEye className='!text-[#858585] mr-2' />
              ) : (
                <VscEyeClosed className='!text-[#858585] mr-2' />
              )
            }
            inputBtnHandler={() => setIsPasswordShow2(!isPasswordShow2)}
            error={formErrors.password_confirmation}
          />
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between items-start w-full'>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <Input
            id='first_name'
            placeholder='Enter first name'
            label='First Name:'
            className='w-full'
            value={formData.first_name}
            onChange={handleChange}
            error={formErrors.first_name}
          />
        </div>
        <div className='flex justify-center items-center w-full md:w-[48%]'>
          <Input
            id='last_name'
            placeholder='Enter last name'
            label='Last Name:'
            className='w-full'
            value={formData.last_name}
            onChange={handleChange}
            error={formErrors.last_name}
          />
        </div>
      </div>
      {error && <p className='text-red-500'>{error}</p>}
      <div className='flex flex-col justify-center items-start w-full mt-10 mb-2'>
        <Button
          text={isLoading ? 'Loading...' : 'Next'}
          className='flex justify-center items-center w-full h-[50px] font-bold text-[24px]'
          // disabled={isLoading || !otpVerified}
        >
          {isLoading && <AiOutlineLoading className='animate-spin ml-2' />}
        </Button>
      </div>
    </form>
  );
};

export default SignUpStep1;
