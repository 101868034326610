const HowItsWork = () => {
  return (
    <section className='flex justify-center items-center w-full bg-[rgb(244,244,244)]'>
      <div className='flex flex-col justify-center items-start gap-16 py-20 w-[90%] lg:w-[80%]'>
        <div className='w-full flex flex-col-reverse md:flex-col justify-center items-start gap-16'>
          <div className='flex justify-start items-start w-[90%]'>
            <ul className='flex flex-col md:flex-row justify-start w-full gap-10 lg:gap-20'>
              <li className='flex flex-col gap-2 md:w-[350px] lg:w-[400px]'>
                <h3 className='text-[24px] font-bold text-[rgb(8,8,8)]'>
                  01: Register
                </h3>
                <p className='text-[20px]'>
                  Register your profile 100% free. No charges required.
                  Matrimonial details required. After registration just verify
                  your profile.
                </p>
              </li>
              <li className='flex flex-col gap-2 md:w-[350px] lg:w-[400px]'>
                <h3 className='text-[24px] font-bold text-[rgb(8,8,8)]'>
                  02: Find your Match
                </h3>
                <p className='text-[20px]'>
                  Easy to search and find the match as per your preferences.
                  Edit your profile and update your Match Preferences.
                </p>
              </li>
            </ul>
          </div>
          <div className='flex justify-start items-start w-full'>
            <ul className='flex flex-col md:flex-row justify-start w-full gap-10 lg:gap-20'>
              <li className='flex flex-col gap-2 md:w-[450px] lg:w-[600px]'>
                <span className='border-l-transparent border-[3px] border-[rgb(8,8,8)] py-8 rounded-r-full relative w-full'>
                  <i className='absolute rounded-full translate-y-[-50%] translate-x-[50%] right-0 top-[50%] w-[16px] h-[16px] bg-[rgb(255,63,96)]' />
                  <h2 className='text-[rgb(8,8,8)] text-[44px] font-bold'>
                    How It's Work
                  </h2>
                  <span className='w-[70%] h-full absolute top-0'>
                    {/* bottom tods L&R */}
                    <i className='absolute rounded-full translate-y-[-50%] translate-x-[-50%] left-0 top-0 w-[16px] h-[16px] bg-[rgb(255,63,96)]' />
                    <i className='absolute rounded-full translate-y-[-50%] translate-x-[50%] right-0 top-0 w-[16px] h-[16px] bg-[rgb(255,63,96)]' />
                    {/* top tods L&R */}
                    <i className='absolute rounded-full translate-y-[50%] translate-x-[-50%] left-0 bottom-0 w-[16px] h-[16px] bg-[rgb(255,63,96)]' />
                    <i className='absolute rounded-full translate-y-[50%] translate-x-[50%] right-0 bottom-0 w-[16px] h-[16px] bg-[rgb(255,63,96)]' />
                  </span>
                </span>
              </li>
              <li className='flex-col gap-2 md:w-[350px] lg:w-[400px] hidden md:flex'>
                <h3 className='text-[24px] font-bold text-[rgb(8,8,8)]'>
                  03: Get Profile Info
                </h3>
                <p className='text-[20px]'>
                  Send interest to Matching profiles. This will notify to the
                  member and they will know you are interested to their profile.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className='flex justify-start items-start'>
          <ul className='flex flex-col md:flex-row justify-start w-full gap-10 lg:gap-20'>
            <li className='md:hidden flex flex-col gap-2 md:w-[350px] lg:w-[400px]'>
              <h3 className='text-[24px] font-bold text-[rgb(8,8,8)]'>
                03: Get Profile Info
              </h3>
              <p className='text-[20px]'>
                Send interest to Matching profiles. This will notify to the
                member and they will know you are interested to their profile.
              </p>
            </li>
            <li className='flex flex-col gap-2 md:w-[350px] lg:w-[400px]'>
              <h3 className='text-[24px] font-bold text-[rgb(8,8,8)]'>
                04: Get Profile Info
              </h3>
              <p className='text-[20px]'>
                View Contact details of any Member 100% Free. No charges /
                payment required.
              </p>
            </li>
            <li className='flex flex-col gap-2 md:w-[350px] lg:w-[400px]'>
              <h3 className='text-[24px] font-bold text-[rgb(8,8,8)]'>
                05 : Self-Manage Profile
              </h3>
              <p className='text-[20px]'>
                Manage your profile yourself. Add, update your information,
                photo, photo album, horoscope.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default HowItsWork;
