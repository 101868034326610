import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import AppRoutes from './Routes';
// import Signup from './pages/Signup/Signup';
// import Signin from './pages/Signin/Signin';
import ProfileCompleteStatus from './pages/ProfileForm/ProfileCompleteStatus/ProfileCompleteStatus';
import Footer from './components/Footer/Footer';
import SuccessMessage from './components/UI/SuccessMessage/SuccessMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMatchingProfiles,
  getUserProfile,
} from './redux/features/userSlice';
import Loading from './components/loading/Loading';
import { fetchCompanyInfo } from './redux/features/companyMasterSlice';
import {
  fetchAllImages,
  fetchImageType,
  fetchprofilePicUrlMyDetailByProfileId,
} from './redux/features/imageSlice';
import Navbar from './components/Navbar/Navbar';

function App() {
  const [activeModal, setActiveModal] = useState(null);
  const [successMessageText, setSuccessMessageText] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchCompanyInfo({ typeId: 2 }));
        await dispatch(fetchImageType());
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, [dispatch]);

  const token = localStorage.getItem('auth_token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const userProfileData = await dispatch(getUserProfile());
          await dispatch(getMatchingProfiles());
          // console.log('userProfileData', userProfileData?.payload?.profile_id);
          const profile_id = userProfileData?.payload?.profile_id;
          if (profile_id) {
            await dispatch(fetchprofilePicUrlMyDetailByProfileId(profile_id));
          }
        }
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, [token, dispatch]);

  const imageTypes = useSelector((state) => state.image.imageTypes);
  useEffect(() => {
    if (token && imageTypes.length > 0) {
      dispatch(fetchAllImages());
    }
  }, [token, dispatch, imageTypes]);

  const showModal = (modal) => setActiveModal(modal);
  const hideModal = () => setActiveModal(null);

  const showSuccessMessageWithText = (text) => {
    // console.log('text: ', text);

    setSuccessMessageText(text);
    showModal('successmessage');
  };

  return (
    <HelmetProvider>
      {/* <GoogleReCaptchaProvider reCaptchaKey={site_key}> */}
      <Loading />
      <Router>
        <Navbar onShowSuccessMessage={showSuccessMessageWithText} />
        {activeModal === 'profilecompletestatus' && (
          <ProfileCompleteStatus onHideProfileCompleteStatus={hideModal} />
        )}
        {activeModal === 'successmessage' && (
          <SuccessMessage
            successMessage={successMessageText}
            onHideSuccessMessage={hideModal}
          />
        )}
        <AppRoutes
          onShowBasicInfo={() => showModal('basicinfo')}
          // onShowSuccessMessage={() => showModal('successmessage')}
          onShowSuccessMessage={showSuccessMessageWithText}
        />
        <Footer isLoggedIn={!!token} />
      </Router>
      {/* </GoogleReCaptchaProvider> */}
    </HelmetProvider>
  );
}

export default App;
